export const blogs = [
    {
        id: 0,
        category : 'blog',
    },
    {
        id: 1,
        category : 'blog',
    },
    {
        id: 2,
        category : 'blog',
    },
    {
        id: 3,
        category : 'blog',
    }
]
