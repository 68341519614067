import * as React from "react"
import ReactModal from 'react-modal'
import Layout from "../components/layout"

import '../scss/main.scss'
import AdvantagesSection from "../components/sections/advantages-section";
import ImageSection from "../components/sections/image-section";

import {advantages} from "../datas/home/advantages";
import {heroData} from "../datas/home/hero-section";
import {purpleData} from "../datas/home/purple-section";
import {backers} from "../datas/home/backers";
import {roadmaps} from "../datas/home/roadmaps";
import {FooterBoxes} from "../datas/home/content";

import Card from "../components/modules/card";
import Button from "../components/buttons/button";
import PurpleSection from "../components/sections/purple-section";
import HeroSection from "../components/sections/hero-section";
import BackersSection from "../components/sections/backers-sections";
import RoadmapItem from "../components/modules/roadmapItem";
import SignUp from "../components/modals/signUp";





import 'react-multi-carousel/lib/styles.css';
import {resources} from "../datas/resources/resources";
import PodcastSection from "../components/sections/podcast-section";
import WhitepapersSection from "../components/sections/whitepapers-section";
import Resource from "../components/modules/resource";
import VideosSection from "../components/sections/videos-section";
import AnchorMenu from "../components/modules/anchorMenu";
import {anchors} from "../datas/resources/content";
import {blogs} from "../datas/resources/blogs";
import {videos} from "../datas/resources/videos";
import {whitepapers} from "../datas/resources/whitepapers";
import {podcasts} from "../datas/resources/podcasts";
import {communityposts} from "../datas/resources/community-posts";
import Carousel from "react-multi-carousel";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide:3,
        partialVisibilityGutter: 140
    },
    laptop: {
        breakpoint: { max: 1340, min: 600 },
        items: 2,
        slidesToSlide:2
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide:2
    },
    mobile: {
        breakpoint: { max: 920, min: 0 },
        items: 1,
        slidesToSlide:1
    }
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    return (
        <div className="carousel-button-group">
            <button className={rest.carouselState.currentSlide === 0  ? 'button button button-left disable' : 'button button button-left'} onClick={() => previous()}><i className={'icon-carousel-left'} aria-label={'prev'}/></button>
            <button className={rest.carouselState.currentSlide + rest.carouselState.slidesToShow === rest.carouselState.totalItems  ? 'button button button-right disable' : 'button button button-right'} onClick={() => next()}><i className={'icon-carousel-right'} aria-label={'next'}/></button>
        </div>
    );
};



class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.enableBackers = false;
        this.state = {
            isModalOpen: false,
            modalType: ''
        }
    }
    handleModalOpen = event => {
        this.setState({ modalType: event.target.id })
        this.setState({ isModalOpen: true })
        document.body.style.overflow = 'hidden';
    }

    handleModalClose = event => {
        this.setState({ isModalOpen: false })
        document.body.style.overflow = 'unset';
    }

    render() {
      return (
          <Layout footerBoxes={FooterBoxes}>
              <div className={'index-page'}>
                  <main>
                      <HeroSection heroData={heroData}/>

                      <div className={'container'}>
                          <h2 className={'with-decor'}>Наши услуги</h2>
                          <AdvantagesSection advantages={advantages}/>

                          
                          <div className={'row my-5 blog-section'}>
                            <h2 className={'with-decor mb-5'}>Наши работы</h2>
                            <div className={'section-inner'}>
                                <Carousel responsive={responsive} swipable={true} draggable={true} infinite={false} containerClass={'multi-carousel'} customTransition={'1000ms all cubic-bezier(0.65, 0, 0.35, 1)'} arrows={false} renderButtonGroupOutside={true} customButtonGroup={<ButtonGroup />}>
                                    {communityposts.map((post,index) => (
                                        <Resource key={index} type={'small'} data={post}/>
                                    ))}
                                </Carousel>

                            </div>
                        </div>

                        <section className={'advantages-section'}>
                        <div className={'row justify-content-center'}>
                <div className={'col-auto'}>
                    <Button type={'internal'} class={'simple'} url={'/cases/'} text={'Посмотреть все кейсы'}/>
                </div>
            </div>
            </section>


                      </div>
                        <ImageSection logo={true} image={'home/it-park.jpg'} title={'Наши мастера обладают прекрасным стилем и высокими профессиональными качествами'} text={<Button class={'external-big'} type={'internal'} url={'/team/'} text={'Наша команда'}/>}/>
                        {/* <PurpleSection enableBackers={this.enableBackers} anchor={'join-the-community'} purpleData={purpleData}/>
                        <BackersSection enableBackers={this.enableBackers} backers={backers}/> */}
                  </main>
                  <ReactModal
                      isOpen={this.state.isModalOpen}
                  >
                      <div className={'inner'}>
                        <SignUp modalType={this.state.modalType}/>
                        <button className={'close-button'} onClick={this.handleModalClose}><i className={'icon-close'} aria-label={'close'}></i></button>
                      </div>
                  </ReactModal>
              </div>
          </Layout>
      )
    }
}

export default IndexPage
