export const communityposts = [
    {
        id: 1,
        category: 'communitypost',
        image: 'resources/nick-gardener.png',
        title: 'Телеканал “Пятница”',
        subtitle: '',
        url: 'https://teletype.in/@simbios.agency/rsuFLMZYAB4',
    },
    {
        id: 2,
        category: 'communitypost',
        image: 'resources/can-gurel-delphi.png',
        title: 'Мобильное приложение Quedu',
        subtitle: '',
        url: 'https://teletype.in/@simbios.agency/NCG15fVCAlW',
    },
    {
        id: 3,
        category: 'communitypost',
        image: 'resources/KideyHealth.png',
        title: 'KideyHealth',
        subtitle: '',
        url: 'https://teletype.in/@simbios.agency/TbzrRjjrVZ7',
    },
    {
        id: 4,
        category: 'communitypost',
        image: 'resources/riley.png',
        title: 'WorkFlow',
        subtitle: '',
        url: '',
    },
]
